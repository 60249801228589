
/***
 *Single project view and the map
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update to typescript.
 */
import { Component, Watch, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip, LIcon } from "vue2-leaflet";
import { SectionStateDTO, SingleDeviceState } from "@/model/ModelSectionState";
import Section from "@/model/ModelSection";
import KilometrageFromatted from "@/mixins/Project/KilometrageFromatted";
import { BatteryDevice } from "@/model/SingleDeviceModal";
import { DeviceProject } from "@/interface/DeviceInterface";
import UserAccess from "@/model/User/UserAccess";

interface FormatDeviceInformationParam {
  deviceNumber: string;
  deviceInformation: SingleDeviceState;
  kilometrage: string;
}

@Component({
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon
  },
  computed: {
    ...mapGetters("projects", {
      allSections: "GetAllSectionInProject",
      allSectionStates: "GetProjectSectionsStatesForMap"
    }),
    ...mapGetters({
      getAllDevices: "getAllDevices"
    }),
    ...mapGetters(["getUserAccess"])
  },
  methods: {
    ...mapMutations("projects", {
      ResetSectionStateMap: "ResetSectionStateMap"
    }),
    ...mapActions("projects", {
      getAllSectionStatus: "GetAllSectionStatus"
    }),
    ...mapActions(["getAllDevicesFromBackEnd"])
  }
})
export default class Map extends Mixins(KilometrageFromatted) {
  // Vuex
  readonly allSections!: Array<Section>;
  readonly allSectionStates!: Array<SectionStateDTO>;

  private ResetSectionStateMap!: Function;
  private getAllSectionStatus!: Function;
  readonly getAllDevices!: Array<BatteryDevice>;
  private getAllDevicesFromBackEnd!: Function;
  readonly getUserAccess!: UserAccess;
  // url: String = "https://{s}.tile.osm.org/{z}/{x}/{y}.png";
  url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  zoom: number = 13;
  center: Array<number> = [49.333336, 8.539612];
  bounds: any = null;
  firstTime = false;

  openPopUps: { [key: string]: any } = {};

  iconSize = 35;

  showBatteries = false;
  async created() {
    this.ResetSectionStateMap([]);
    await this.getAllDevicesFromBackEnd();
  }

  @Watch("allSections", { immediate: true })
  async getAllSectionsIds() {
    this.ResetSectionStateMap([]);
    await this.getAllSectionStatus(this.$route.params.id);
    for (const [key] of Object.entries(this.openPopUps)) {
      if (this.openPopUps[key] === "open") {
        this.openAfterUpdate(key);
      }
    }
  }

  get formatTheState() {
    let formattedSectionData: Array<any> = [];
    //Get the gps for each device
    this.allSectionStates.forEach((item: SectionStateDTO) => {
      let deviceStateOne: SingleDeviceState | null = item.state.stateDetails.deviceStateOne;
      let deviceStateTwo: SingleDeviceState | null = item.state.stateDetails.deviceStateTwo;
      let section = this.allSections.find((sec: Section) => sec.id === item.sectionId);

      if (deviceStateOne && deviceStateOne.gpsPosition) {
        let sectionOneDetails = this.formatDeviceInformation({
          deviceNumber: "One",
          deviceInformation: deviceStateOne,
          kilometrage: section ? section?.kilometrage : "0"
        });
        formattedSectionData.push(sectionOneDetails);
      }
      if (deviceStateTwo && deviceStateTwo.gpsPosition) {
        let sectionOneDetails = this.formatDeviceInformation({
          deviceNumber: "Two",
          deviceInformation: deviceStateTwo,
          kilometrage: section ? section?.kilometrage : "0"
        });
        formattedSectionData.push(sectionOneDetails);
      }
    });

    var projectId = this.$route.params.id;
    if (this.getUserAccess.Access.VIEW.project.batteryList && this.showBatteries) {
      this.getAllDevices.forEach((device: any) => {
        if (
          device.type.name === "battery" &&
          device.projects.some((project: DeviceProject) => {
            return project.id.toString() === projectId;
          })
        ) {
          let battery = this.formatBatteryInformation(device);
          formattedSectionData.push(battery);
        }
      });
    }

    if (formattedSectionData.length && !this.firstTime) {
      this.firstTime = true;
      this.center = formattedSectionData[0].position;
    }
    return formattedSectionData;
  }

  get dynamicSize() {
    return [this.iconSize, this.iconSize * 1.25];
  }

  get dynamicAnchor() {
    return [this.iconSize / 2, this.iconSize * 1.25];
  }

  /*----------  Methods  ----------*/
  zoomUpdated(zoom: number) {
    this.zoom = zoom;
  }

  centerUpdated(center: Array<number>) {
    this.center = center;
  }

  boundsUpdated(bounds: any) {
    this.bounds = bounds;
  }

  formatDeviceInformation({ deviceNumber, deviceInformation, kilometrage }: FormatDeviceInformationParam) {
    let sectionDetails = {
      device: deviceNumber,
      kilometrage,
      position: {
        lat: deviceInformation.gpsPosition ? deviceInformation.gpsPosition.latitude : null,
        lng: deviceInformation.gpsPosition ? deviceInformation.gpsPosition.longitude : null
      },
      mainBoardId: deviceInformation.mainboardUid,
      batteryStatus: deviceInformation.deviceBatteryState?.chargingCurrent,
      battery: deviceInformation.deviceBatteryState
        ? deviceInformation.deviceBatteryState.batteryStatus
        : this.$t("utils.noInformation"),
      batteryValue: deviceInformation.deviceBatteryState
        ? deviceInformation.deviceBatteryState.batteryTerminalCurrent
        : this.$t("utils.noInformation"),
      slaveId: deviceInformation.slaveboardUid,
      status: deviceInformation.status,
      display: deviceInformation.picture,
      width: deviceInformation.width,
      height: deviceInformation.height,
      brightnessAuto: deviceInformation.brightnessAuto,
      brightness: deviceInformation.brightness,
      prewarning: deviceInformation.prewarningLamp,
      cbRadio: deviceInformation.cbRadio
    };
    return sectionDetails;
  }
  formatBatteryInformation(battery: any) {
    let sectionDetails = {
      device: "Battery",
      deviceId: battery.id,
      emnifyIccId: battery.emnifyIccId,
      position: {
        lat: battery.latitude ? battery.latitude : null,
        lng: battery.longitude ? battery.longitude : null
      },
      gpsLastUpdated: battery.gpsLastUpdated,
      serialNumber: battery.serialNumber,
      type: battery.type.name
    };
    return sectionDetails;
  }
  handleOpenPopUp(id: number) {
    if (!(id in this.openPopUps)) {
      this.openPopUps[id] = "open";
      return;
    }
    this.openPopUps[id] === "open";
  }

  openAfterUpdate(mainboardId: number | string) {
    let elementOpen: HTMLElement | null = document.querySelector(`[title=ID-${mainboardId}]`);
    if (elementOpen) {
      elementOpen.click();
    }
  }

  handleClose(mainboardId: number) {
    if (this.openPopUps[mainboardId]) {
      this.openAfterUpdate(mainboardId);
      this.openPopUps[mainboardId] = "close";
    }
  }
}
